@mixin shutterfx($number-of-items) {
  ul {
    @for $i from 1 through $number-of-items {
      li:nth-child(#{$i}) {
        transition: all .2s * $i ease-in-out;
        transform: scale(.5);

        opacity: 0; } } } }

@mixin slidefx($number-of-items) {
    ul {
      @for $i from 1 through $number-of-items {
        li:nth-child(#{$i}) {
          transition: all .2s * $i ease-in-out;
          transform: translate3d(-100%,0,0);
          opacity: 0; } } } }

// Menu Animation Mixins
// Sass - indented style syntax, yes please!
// Tab Size: 2 just in case you copy/paste this



@media (max-width: 991px) {




  // Default demo styles



  a {
    text-decoration: none;
    transition: all .3s ease-in-out; }

  ul,li {
    padding: 0;
    margin: 0; }





  nav {



    .menu-list {
      overflow: hidden;
      max-height: 0;
      margin-top: .5em;
      transition: all .6s ease-in-out; }

    .open {


      li {
        list-style: none;
        border-top: 1px solid #fff; }

      li a {
        display: block;
        background: #eee;
        padding: 0 10px;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: -0.35px;
        color: #050505;


        &:hover {
          background: darken(#eee,20);
          color: #fff; } } } }

  // MAM styles
  // 5 = number of list-items in menu
  .shutterfx {
    @include shutterfx(5); }

  .slidefx {
    @include slidefx(5); }

  .shutterfx ul.open li,.slidefx ul.open li {
    transform: none;
    opacity: 1; } }


