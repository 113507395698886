.services-header{
  position: relative;
  padding-top: 84px;
  &__back{
    text-align: right;
    font-size: 22px;
    font-weight: 600;
    color:#050505;
    position: absolute;
    right: 0;
    top: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 20;
    img{
      position: relative;
      display: inline-block;
      margin-right: 7px;
      top: -2px;
    }
    .arrow{

    }
    span{
      display: inline-block;

      padding-left: 30px;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        background: url("../images/arrow-back.png") no-repeat center;
        width: 20px;
        height: 15px;
        left: 0;
        top: 2px;
        bottom: 0;
        margin: auto;
        transition: all 0.3s ease;
        z-index: 1;
      }
    }
    &:hover{
      span{
        &:before{
          left: -10px;
        }
      }
    }

  }
  &__item{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 30px 0 38px;
    border-radius: 100px 0px 0px 100px;
    &:after{
      border-radius: 100px 0px 0px 100px;
    }
    &.vr{
      position: relative;
      width: 100%;
      &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;

        background: linear-gradient(44.09deg, #002377 0%, #44C3FB 100%);
        box-shadow: 0px 28px 50px rgba(61, 180, 239, 0.4);
      }

    }
    &.ar{
      position: relative;
      width: 100%;
      &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;

        background: linear-gradient(44.09deg, #956100 0%, rgba(255, 220, 101, 0.6) 100%);
        box-shadow: 0px 28px 50px rgba(249, 223, 143, 0.4);
      }
    }
    &.dr{
      position: relative;
      width: 100%;
      &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;

        background: linear-gradient(44.09deg,#004329,#b2ea84);;
        box-shadow: 0 28px 50px rgba(144,202,114,.5);
      }
    }
    &.web{
      position: relative;
      width: 100%;
      &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;

        background: linear-gradient(44.09deg, rgb(71, 0, 83), rgba(230, 87, 255, 0.61));
        box-shadow: 0 28px 50px rgba(226,131,243,.4);
      }
    }
    &.about{
      position: relative;
      width: 100%;
      &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        background: linear-gradient(44.09deg,#898989,#585858 .01%,hsla(0,0%,91%,.56));
        box-shadow: 0 28px 50px hsla(0,0%,63%,.4);
      }
    }
  }
  &__icon{
    position: relative;
    z-index: 2;
    width: 448px;
    padding: 0 24px;
    margin-top: -115px;
  }
  &__content{
    position: relative;
    z-index: 2;
    width: calc(100% - 448px);
    transition: all 1s;
    opacity: 0;
  }
  &__title{
    font-size: 45px;
    font-weight: 800;
    letter-spacing: -0.5px;
    &.vr{
      color:#c2eaff;
    }
    &.ar{
      color:#feffc2;
    }
    &.dr{
      color:#dbffd8;
    }
    &.web{
      color:#ffe8fe;
    }
    &.about{
      color:$white;
    }
  }
  &__subtitle{
    font-size: 24px;
    font-weight: bold;
    color:#fff;
    margin-bottom: 18px;
    span{
      opacity: 0.7;
    }
  }
  p{
    font-size: 16px;
    font-weight:600;
    line-height: 24px;
    color:#fff;
    & + p{
      margin-top: 26px;
    }
  }
  @media (min-width: 1200px) {
    padding-top: 145px;
    &__back{
      top: 90px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    &__icon{
      width: 40%;

    }
    &__content{
      width: 55%;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 52px;
    &__item{
      border-radius: 28px 0px 0px 28px;
      &:after{
        border-radius: 28px 0px 0px 28px;
      }
    }
    &__content{
      width: 100%;
      padding-left: 54px;


    }
    &__icon{
      transform: scale(0.9);
      margin-top: -230px;
      width: 100%;
      text-align: center;
    }
    &__back{
      top: -28px;
      z-index: 12;
    }
   /* &__title{
      font-size: 32px;
    }
    &__subtitle{
      font-size: 22px;
    }*/
  }
  @media (max-width: 767px) {
    padding-top: 52px;
    &__item{
      border-radius: 28px 0px 0px 28px;
      &:after{
        border-radius: 28px 0px 0px 28px;
      }
    }
    &__content{
      width: 100%;
      padding-left: 24px;


    }
    &__icon{
      transform: scale(0.8);
      margin-top: -185px;
      width: 100%;
      text-align: center;
    }
    &__back{
      top: -28px;
    }
    &__title{
      font-size: 32px;
    }
    &__subtitle{
      font-size: 22px;
    }
  }
  @media (max-width: 379px) {
    &__icon{
      margin-top: -55%;
    }
  }
}

.svr{
  .services-detail__list{
    li{
      &:before{
        background:  linear-gradient(51.34deg, #0492E8 0%, #19E3DD 100%);

      }
    }
  }
  .services-detail__box{
    &:before{
      background:  linear-gradient(51.34deg, #0492E8 0%, #19E3DD 100%);
    }
  }
}
.services-detail{
  &__box{
    position: relative;
    border-left: 1px solid rgba(#383838,0.2);
    margin-left: 30px;
    padding-left: 20px;
    margin-top: 80px;
    &:before{
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      left: -8px;

    }
    &:first-child{
      margin-top: 60px;
    }
  }
  .services-detail__box-contacts{
    //min-height: 50vh;
    margin-top: 0px;
    padding-bottom: 40px;
    padding-top: 80px;
    &:after{
      content: "";
      position: absolute;
      width: 1px;
      height: 80px;
      background: $white;
      top: 0;
      left: -1px;
      z-index: 2;
    }

  }

  &__item{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color:#050505;
    position: relative;
    top: -4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 64px;
    &:first-child{
      margin-top: 0;
    }

  }
  &__subtitle{
    font-size: 16px;
    font-weight: 800;
    color:#050505;
    text-transform: uppercase;
    opacity: 0.4;
    margin-bottom: 12px;
    width: 100%;
  }
  &__title{
    font-size: 24px;
    font-weight: bold;
    color:#050505;
    margin-bottom: 30px;
    width: 100%;
    span{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 1px;
        background: #050505;
      }
    }
  }
  p + .services-detail__list{
    margin-top: 24px;
  }
  &__list{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color:#050505;
    li{
      margin-bottom: 24px;
      padding-left: 16px;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        left: 0;
        top: 6px;
      }
    }
  }
  &__content{
    width: calc(100% - 506px);
  }
  .mejs__video{
    //max-width: 100% !important;
    max-height: 100% !important;
    max-width: 476px !important;
    margin: 0 auto;
    border-radius: 12px;
  }
  .mejs__controls:not([style*="display: none"]){
    border-radius: 12px;
  }

  &__fotos{
    width: 476px;
    max-height: 276px;
    border-radius: 12px;

    .gallery-top{
      max-width: 492px;
      .swiper-slide{
        width: 476px;
        height: 276px;
        max-width: 100%;
        border-radius: 12px;
        overflow: hidden;
        video{

          /*max-width: 490px;
          width: 476px;
          max-height: 100%;
          height: 276px;*/
          border-radius: 12px;
          //overflow: hidden;
        }

      }
    }



    video{
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 12px;
      height: 276px;
      overflow: hidden;
      transform: scale(1.08);
    }
    &.nosl{
      overflow: hidden;
      img{
        width: 100%;
        object-fit: cover;
      }
    }
  }
  &__others{
    margin-top: 24px;
    font-weight: 600;
    max-width: 840px;
  }
  &__tabs{
    ul{
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-end;
      margin-bottom: 16px;
      padding-bottom: 16px;
      margin-top: 17px;
      overflow: auto;
      width: 100vw;
      padding-right: 20px;
      li{
        & + li{
          margin-left: 2px;
        }
        &:last-child{
          a{
            margin-right: 90px;
          }
        }
        a{
          display: block;
          background: rgba(5, 5, 5, 0.05);
          border-radius: 12px 12px 0 0;
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          color:rgba(#050505, 0.6);
          text-transform: uppercase;
          padding: 4px 32px 7px;
          white-space: nowrap;
        }
        &.ui-tabs-active{
          a{
            background: #050505;
            opacity: 1;
            color:#fff;
            padding-top: 8px;
          }
        }
      }
    }
    .tabs-content{
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color:#050505;
      ul{
        li{
          list-style:circle;
        }
      }
    }
  }
  p + p{
    margin-top: 24px;
  }
  .gallery-top{
    margin-bottom: 16px;
    .swiper-slide{
      text-align: center;
    }
    img{
      height: 276px;
      object-fit: cover;
      border-radius: 12px;
      width: 100%;
    }

  }
  .gallery-thumbs , .gallery-thumbs2, .gallery-thumbs3{
    padding: 0 24px;
    position: relative;
    max-width: 492px;

    .slick-slide{
      border-radius: 12px;
    }
    &:before, &:after{
      content: "";
      position: absolute;
      width: 24px;
      height: 100%;
      background: #fff;
      top: 0;
      z-index: 2;
    }
    &:before{
      left: 0;
    }
    &:after{
      right: 0;
    }
    .swiper-button-arrows{
      .swb{
        width: 11px;
        height: 22px;
        background: url("../images/arrow-sl.png") no-repeat center;
        transform: rotate(360deg);
        margin-top: -11px;
      }
      .swiper-button-prev{
        transform: rotate(-180deg);
        left: 0;
      }
      .swiper-button-next{
        right: 0;
      }

    }
    img{
      width: 100%;
      height: 60px;
      max-height: 60px;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  &__contacts{}
  &__phone, &__email{
    display: block;
    font-size: 16px;
    font-weight: bold;
    color:#050505;
    span{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        width: 0;
        height: 1px;
        background: #050505;
        bottom: 0;
        left: 0;
        transition: width 0.3s ease;
      }
    }

    &:hover{
      color:#050505;
      span{
        &:before{
          width: 100%;
        }
      }
    }
  }
  &__email{
    margin-top: 10px;
  }
  &__social{
    margin-top: 10px;
    a{
      display: inline-block;
      & + a{
        margin-left: 12px;
      }
    }
  }
  @media (min-width: 1200px) {
    margin-top: 60px;
  }
  @media (max-width: 991px) {
    &__item{
      &.one .services-detail__fotos {
        margin-top: 54px;
      }
    }
    &__fotos{
      width: 100%;
      max-height: none;
      margin-top: 54px;
      text-align: center;

      &.nosl{
        &.fvideo{
          max-height: 276px;
          margin-top: 32px;
        }
      }

    }
    &__content{
      width: 100%;
    }
    &__others{
      margin-top: 54px;
    }
    &__item{
      margin-top: 51px;
    }
  }
  @media (max-width: 767px) {
    &__box{
      margin-left: 0;
      margin-top: 64px;
    }
    &__content{
      width: 100%;
    }
    &__item{
      margin-top: 33px;
      &.one{
        .services-detail__fotos{
          margin-top: 33px;
        }

      }
    }
    .mejs__video{
      //max-width: 100% !important;
      max-height: 100% !important;
      max-width: 100% !important;
      margin: 0 auto;
      border-radius: 12px;
    }
    &__fotos{
      width: 100%;
      max-height: none;
      margin-top: 36px;
      &.nosl{

        &.fvideo{
          margin-top: 12px;
          max-height: 160px;
          video{

            width: 100%;
            max-height: 100%;
            //height: 160px;
            max-width: 100%;
            border-radius: 12px;
            //overflow: hidden;
          }
        }
      }
      .gallery-top{
        .swiper-slide{
          width: 100%;
          height: 160px;
          max-width: 100%;
          border-radius: 12px;
          overflow: hidden;
          video{

            width: 100%;
            max-height: 100%;
            height: 160px;
            max-width: 100%;
            border-radius: 12px;
            //overflow: hidden;
          }

        }
      }



      video{
        width: 100%;
        max-width: 100%;
        max-height: 300px;
        border-radius: 12px;
        height: 276px;
        overflow: hidden;
      }
    }
    .gallery-top{
      img{
        height: 100%;
      }
    }
    &__tabs{
      ul{
        li{
          a{
            font-size: 16px;
          }
        }
      }
    }
    &__box-contacts{
      .services-detail__item{
        margin-top: 34px
      }
    }
    &__contacts{
      margin-bottom: 33px;
    }
  }
}



.back {
  color: inherit;
}


.services-detail__box-contacts{
  .services-detail__item{
    margin-top: 15px;
  }
}

.main-services{
  @media (min-width: 1200px) and (max-width: 1359px){
    padding-left: 75px;
  }
  @media (max-width: 1199px){
    padding-left: 56px;
  }
  @media (max-width: 991px) {
    padding-left: 40px;
  }
}
.menu-left-box{
  transform: rotate(-90deg);
  left: -100px;
  position: fixed;
  bottom: 243px;

  @media (min-width: 1360px) {
    left: calc(((100vw - 1200px) / 2) - 225px);
  }
  @media (max-width: 1359px) {
    left: -154px;
  }
  @media (max-width: 1199px) {
    left: -154px;
  }
  @media (max-width: 991px) {

  }
  @media (max-width: 991px) {
    left: -174px;
  }
  @media (min-height: 600px) {
    bottom: 326px;
  }
}
.menu-left{
  a{
    display: inline-block;
    font-size: 22px;
    font-weight: bold;
    color:#050505;
    opacity: 0.5;
    & + a{
      margin-left: 38px;
    }
    &.active{
      opacity: 1;

    }
    &.active-src{
      opacity: 1;
    }
    &.active1{
      opacity: 1;

    }
    &.no-active{
      opacity: 0.5;
    }


  }
}
.pjax-container{
  position: relative;
}
footer.footer.footer-main-home {
  @media (min-width: 992px) {
    //margin-top: 44px;
  }

}
.footer-main-home.nt{
  display: none;
}
.main-services-copy{//main-vr
  position: absolute;
  top: 0;
  left: 14px;
  height: calc(100vh - 116px - 100px);
  padding-top: 36px;//36
  opacity: 0;
  z-index: 10;
  pointer-events: none;
  transition: all 0.4s ease;
  max-width: 1170px;
  //margin: 0 auto;
  .services-detail{
    background: #fff;
  }
  .home-sl__item{
    width: 249px;
    margin-right: 38px;
    display: block;
    transition: all .7s ease;
    &:after{
      transition: width 1s ease;
    }
  }
  .services-header__title{
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.5s ease 0.2s;
  }
  .services-header__subtitle{
    transform: translateY(50%);
    opacity: 0;
    transition: all 0.6s ease 0.3s;
  }
  .services-header__txt{
    transform: translateY(20%);
    opacity: 0;
    transition: all 0.7s ease 0.5s;
  }
  .services-header__icon{
    img{
      transform: scale(0.6);
      transition: all 0.7s ease;
    }
  }
  .services-detail{
    opacity: 0;
    transition: all 0.3s ease ;
  }


  &.trans{
    /* transition: all 1.5s ease;
   transform: translateX(-200%);*/

  }
  .main-services{
    padding-left: 0 !important;
    box-sizing: content-box;
  }
  .services-header__item{

    transition: all 1s ease ;
    border-radius: 28px 0 0 28px ;
    &:after{
      width: 0px;
      transition: width 1s ease;
      border-radius: 28px 0 0 28px;
    }
  }
  &.active{
    opacity: 1;
    pointer-events: auto;

    .services-header__item{
      border-radius: 100px 0 0 100px;
      &:after{
        width: 100vw;
        border-radius: 100px 0 0 100px;
      }
    }
    .services-header__icon{
      img{
        transform: scale(1);
      }
    }
    .services-header__title{
      transform: translateX(0%) translateY(0%);
      opacity: 1;
      transition: all 0.5s ease 0.2s;
    }
    .services-header__subtitle{
      transform: translateY(0%);
      opacity: 1;
      transition: all 0.5s ease 0.3s;
    }
    .services-header__txt{
      transform: translateY(0%);
      opacity: 1;
      transition: all 0.5s ease 0.4s;
    }
    .services-detail{
      opacity: 1;

    }

    .services-header{
      width: 100%;
      height: auto;

    }
    .services-header__content{
      opacity: 1;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 56px;
  }
  @media (max-width: 991px) {
    padding-left: 40px;
    @media (max-width: 991px) {
      &.active{
        .services-header__item{
          border-radius: 46px 0 0 46px ;
          &:after{
            width: 100vw;
            border-radius: 46px 0 0 46px ;
          }
        }
      }
    }
  }
}

.ms-active{
  #content{
    display: none;
  }
}
.services-detail{
  @media (min-width: 1200px) {
    -webkit-box-shadow: -2px 10px 28px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px 10px 28px -11px rgba(0,0,0,0.75);
    box-shadow: -2px 10px 28px -11px rgba(0,0,0,0.75);
    padding: 1px 23px 15px 0;
  }
}
.main-services{//main-vr
  position: relative;
  //top: 0;
  //left: 14px;
  //height: calc(100vh - 116px - 100px);
  padding-top: 36px;//36
  opacity: 0;
  //z-index: 10;
  pointer-events: none;
  /*transition: all 0.6s ease;*/
  max-width: 1170px;
  margin: 0 auto;
  .services-detail{
    //background: #fff;
  }
  .home-sl__item{
    width: 249px;
    margin-right: 38px;
    display: block;
    transition: all .7s ease;
    &:after{
      transition: width .7s linear;
    }
  }
  .services-header__title{
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.5s ease 0.2s;
  }
  .services-header__subtitle{
    transform: translateY(50%);
    opacity: 0;
    transition: all 0.6s ease 0.3s;
  }
  .services-header__txt{
    transform: translateY(20%);
    opacity: 0;
    transition: all 0.7s ease 0.5s;
  }
  .services-header__icon{
    opacity: 0;
    transition: all 0.3s ease 0.1s;
    img{
      transform: scale(0.6);
      transition: all 0.7s ease;
    }
  }
  .services-detail{
    opacity: 0;
    transition: all 0.3s ease 0.4s;
  }


  &.trans{
     /* transition: all 1.5s ease;
    transform: translateX(-200%);*/

  }
  .main-services{
    padding-left: 0 !important;
    box-sizing: content-box;
  }
  .services-header__item{

    transition: all 0.5s ease ;
    border-radius: 28px 0 0 28px ;
    &:after{
      width: 0px;
      transition: width 1s ease;
      border-radius: 28px 0 0 28px;
    }
  }
  &.active{
    opacity: 1;
    pointer-events: auto;

    .services-header__item{
      border-radius: 100px 0 0 100px;
      &:after{
        width: 100vw;
        border-radius: 100px 0 0 100px;
      }
    }
    .services-header__icon{
      opacity: 1;
      img{
        transform: scale(1);
      }
    }
    .services-header__title{
      transform: translateX(0%) translateY(0%);
      opacity: 1;
      transition: all 0.5s ease 0.2s;
    }
    .services-header__subtitle{
      transform: translateY(0%);
      opacity: 1;
      transition: all 0.5s ease 0.3s;
    }
    .services-header__txt{
      transform: translateY(0%);
      opacity: 1;
      transition: all 0.5s ease 0.4s;
    }
    .services-detail{
      opacity: 1;

    }

    .services-header{
      width: 100%;
      height: auto;

    }
    .services-header__content{
      opacity: 1;
    }
  }

  @media (max-width: 991px) {
    &.active{
      .services-header__item{
        border-radius: 46px 0 0 46px ;
        &:after{
          width: 100vw;
          border-radius: 46px 0 0 46px ;
        }
      }
    }
  }
}
.sloader{
  #p_prldr{
    position: fixed;
    left: 0;
    top: 0;
    right:0;
    bottom:0;
    background: #fff;
    z-index: 30;}

  .contpre small{font-size:25px;}

  .contpre{
    width: 250px;
    height: 100px;
    position: absolute;
    left: 50%;top: 48%;
    margin-left:-125px;
    margin-top:-75px;
    color:#fff;
    font-size:40px;
    letter-spacing:-2px;
    text-align:center;
    line-height:35px;}

  #p_prldr .svg_anm {
    position: absolute;
    width: 41px;
    height: 41px;
    background: url(../images/oval.svg) center center no-repeat;
    background-size:41px;
    margin: -16px 0 0 -16px;}
}

.main-services-header{

  width: 100%;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  z-index: 21;
  top: 60px;//82
  //transform: translateY(-50px);
  transition:  all 0.1s ease-in-out;

  .services-header{
    padding-top: 0;
    //padding-left: 75px;
  }
  .services-header__item{
    height: 75px;
    transition: all 0.3s ease-out 0s;
  }
  .services-header__icon{
    width: 225px;
    top: 9px;
    left: -75px;
    transition: all 0.3s ease 0s;
    position: relative;
  }
  .services-header__title{
    position: relative;
    top: -25px;
    left: -70px;
    transition: all 0.3s ease 0s;
  }
  .services-header__content{
    opacity: 1;
  }
  .services-header__back{
    z-index: 3;
    color: #fff !important;
    top: 44px;
    right: 0px;
    span{
      &:before{
        background: url("../images/arrow-back-white.png") no-repeat center;
      }
    }
  }
  &.fix{
    opacity: 1;
    transform: translateX(0px);
    pointer-events: auto;
  }
}

.nav {
  position: fixed;
  right: 30px;
  top: 10px;
  background: rgba(205,182,141,0.5);
  z-index: 100;
}
.nav > li {
  list-style: none;
  padding-bottom: 10px;
}
.nav > li a {
  color: blue;
  display: block;
  padding: 3px 0;
}
.nav > li a:hover {
  color: green;
}
.nav > li a.active {
  color: white;
  background-color: green;
}
.page-home{

}
.ms-active{
  #content{

    @media (min-width: 992px) {
      margin-top: -44px;
    }
    @media (min-width: 1200px) {
      margin-top: -157px;
    }
  }
}

.home-sl-next, .home-sl-prev{
  position: absolute;
  width: 100px;
  height: 100%;
  top: 0;

  cursor: pointer;
  z-index: 2;
}
.home-sl-next{
  right: 0;
}
.home-sl-prev{
  left: 0;
}

.services-detail{
  &.vr{
    .services-detail__box{
      &:before{
        background: linear-gradient(44.09deg, #002377 0%, #44C3FB 100%);

      }
    }
    .services-detail__list{
      li{
        &:before{
          background: linear-gradient(44.09deg, #002377 0%, #44C3FB 100%);
        }
      }
    }
  }
  &.ar{
    .services-detail__box{
      &:before{
        background: linear-gradient(44.09deg, #956100 0%, rgba(255, 220, 101, 0.6) 100%);

      }
    }
    .services-detail__list{
      li{
        &:before{
          background: linear-gradient(44.09deg, #956100 0%, rgba(255, 220, 101, 0.6) 100%);
        }
      }
    }
  }
  &.dr{
    .services-detail__box{
      &:before{
        background: linear-gradient(44.09deg,#004329,#b2ea84);

      }
    }
    .services-detail__list{
      li{
        &:before{
          background: linear-gradient(44.09deg,#004329,#b2ea84);
        }
      }
    }
  }
  &.web{
    .services-detail__box{
      &:before{
        background: linear-gradient(44.09deg, rgb(71, 0, 83), rgba(230, 87, 255, 0.61));


      }
    }
    .services-detail__list{
      li{
        &:before{
          background: linear-gradient(44.09deg, rgb(71, 0, 83), rgba(230, 87, 255, 0.61));

        }
      }
    }
  }
  &.about{
    .services-detail__box{
      &:before{
        background: linear-gradient(44.09deg,#898989,#585858 .01%,hsla(0,0%,91%,.56));


      }
    }
    .services-detail__list{
      li{
        &:before{
          background: linear-gradient(44.09deg,#898989,#585858 .01%,hsla(0,0%,91%,.56));

        }
      }
    }
  }
}

.services-detail__mapicon{
  height: 120px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
  img{
    height: 100%;
    object-fit: cover;
  }
}

.services-detail__contacts{
  .services-detail__subtitle{
    text-align: center;
  }
}

.nosl{
  &.nosl-hauto{
    height: auto;
    max-height: none;
    img{
      border-radius: 12px;
    }
  }
  img + p{
    margin-top: 30px;
  }
}
.services-detail__fotos-boxc{
  height: 356px;
  &.nmt{
    margin-top: 0;
  }
}
.services-detail__fotos-boxc .services-detail__fotos{
  margin-top: 0 !important;
}

.services-detail__item-slw100{
  width: 100%;
  padding: 0 30px;
  margin-top: 34px;
  .item{
    height: 173px;
    border-radius: 12px;
    margin: 0 10px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  .slick-arrow{
    background: url(../images/arrow-grey.png) no-repeat 50%;
    width: 9px;
    height: 15px;
    transform: rotate(360deg);
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .slick-next{
    right: 0;

  }
  .slick-prev{
    left: 0;
    transform: rotate(180deg);
  }
  @media (max-width: 767px) {
    .item{
      height: 120px;
    }
  }
}

.services-detail__content{
  ol{
    margin-left: 14px;
  }
}
.mejs__poster{
  width: 100% !important;
  height: 100% !important;
}
video[poster]{
  height:100%;
  width:100%;
  object-fit: cover;
}
.mejs__background, .mejs__mediaelement{
  overflow: hidden;
}
.mejs__mediaelement, .mejs__poster{
  border-radius: 12px;
}

.dr-portfolio{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 26px;
  &__item{
    width: 48%;
  }
  &__title{
    text-align: center;
    width: 100% ;
  }
  .services-detail__content{
    width: 100%;
  }
  .services-detail__item{
    margin-top: 0;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    &__item{
      .services-detail__fotos{
        width: 423px;
      }
    }
  }
  @media (max-width: 991px) {
    &__item{
      width: 100%;
      margin-bottom: 30px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    &__title{
      text-align: left;
    }
    .services-detail__fotos-boxc{
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.services-detail__fotos-boxc{
  @media (max-width: 991px) {
    max-width: 100%;
  }
}
.portfolio-dr2{
 .services-detail__fotos{
   margin-top: 0;
 }
  @media (max-width: 991px) {
    .services-detail__fotos{
      margin-bottom: 30px;
      order: 3;
    }
  }
}
.services-detail__fotos-boxc{
  @media (max-width: 991px) {
    height:auto;
  }
}

.gallery-top .swiper-button-arrows{
  display: none;
}


.gallery-thumbs{

}
.mejs__overlay{
  width: 100% !important;
  height: 100% !important;
}
.mejs__overlay-button{
  opacity: 1;
  z-index: 5;
}
.fvideo-auto{
  width: 396px;
  max-height: none !important;
  border-radius: 0;
  @media (max-width: 991px) {
    margin-left: auto;
  }
}
.services-detail .fvideo-auto .mejs__video{

  max-width: 393px !important;
  width: 100% !important;
  .mejs__controls{
    display: none;
    opacity: 0 !important;
  }
  .mejs__overlay-button{
    opacity: 0;
  }
}

.mejs__inner, .mejs__layers{
  width: 100%;
  height: 100%;
}

.services-detail__fotos .gallery-top{
  @media (max-width: 767px) {

  }
}
video {background: white; }

.fvideo-auto{
  video, .mejs__inner, .mejs__layers{
    width: auto !important;
    height: auto !important;
  }
  .mejs__video {
    max-width: none !important;
    width: auto !important;
  }
}

.services-detail__item-slw100{
  .item-video{
    position: relative;
    display: block;
    &:before{
      content: "";
      position: absolute;
      background: url("mejs-controls.svg") no-repeat;
      background-position: 0 -39px;
      height: 80px;
      width: 80px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

    }
  }
}


.w100{
  width: 100%;
}
.mt-0{
  margin-top: 0;
}
.mb40{
  margin-bottom: 40px;
}
.mb20{
  margin-bottom: 15px;
}
p{
  text-align: left;
}
.list-violet{
  text-align: left;
  li{
    position: relative;
    padding-left: 15px;
    margin-bottom: 5px;
    &:before{
      content: "";
      position: absolute;
      width: 5px;
      height: 9px;
      background: url("../images/arrow-violet-9-14.png") no-repeat center;
      background-size: cover;
      left: 0;
      top: 1px;
      bottom: 0;
      margin: auto;
    }
  }
}

.web-step{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: 28px;
    width: 15.5%;
    padding: 12px 7px 12px 12px;
    margin: 0 5px 25px;
    &.blue{
      background: linear-gradient(23.2deg, #002377 0%, #44C3FB 100%);
      box-shadow: 0px 28px 50px rgba(61, 180, 239, 0.4);
    }
    &.orange{
      background: linear-gradient(23.2deg, #956100 0%, rgba(255, 220, 101, 0.6) 100%);
      box-shadow: 0px 28px 50px rgba(249, 223, 143, 0.4);
    }
    &.green{
      background: linear-gradient(23.2deg, #004329 0%, #B2EA84 100%);
      box-shadow: 0px 28px 50px rgba(144, 202, 114, 0.5);
    }
    &.violet{
      background: linear-gradient(23.2deg, #470053 0%, rgba(230, 87, 255, 0.61) 100%);
      box-shadow: 0px 28px 50px rgba(226, 131, 243, 0.4);
    }
    &.blue-lite{
      background: linear-gradient(23.2deg, #0492E8 0%, #19E3DD 100%);
      box-shadow: 0px 28px 50px rgba(14, 184, 227, 0.3);
    }
    &.grey{
      background: linear-gradient(23.2deg, #898989 0%, #585858 0.01%, rgba(232, 232, 232, 0.56) 100%);
      box-shadow: 0px 28px 50px rgba(160, 160, 160, 0.4);
    }
  }
  &__icon{
    margin-right: 10px;
  }
  &__title{
    font-size: 14px;
    line-height: 1.2;
    color:$white;
  }
  @media (max-width: 1199px) {
    &__item{
      width: 30%;
    }
  }
  @media (max-width: 767px) {
    &__item{
      width: 45%;
    }
  }
  @media (max-width: 529px) {
    &__item{
      width: 100%;
    }
  }
}

.web-portfolio{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__item{
    width: 30%;
    margin-bottom: 30px;

  }
  &__foto{
    display: block;
    width: 100%;
    height: 185px;
    border-radius: 12px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 12px;
    }
  }
  .services-detail__title{
    margin-bottom: 15px;
  }
  @media (max-width: 1199px) {
    &__item{
      width: 30%;
    }
  }
  @media (max-width: 991px) {
    &__item{
      width: 45%;
    }
  }
  @media (max-width: 529px) {
    &__item{
      width: 100%;
    }
  }
}

.services-header__title.web{
  @media (min-width: 1200px) {
    font-size: 40px;
  }
}

.web-content{
  @media (min-width: 768px) and (max-width: 991px) {
    .services-detail__content{
      width: 45%;
    }
    .services-detail__fotos{
      width: 45%;
    }
  }
}
.about-anchor{
 .services-detail__content{
   width: 100%;
 }
}
.about-us{
  margin-bottom: 60px;
  &__logo{
    text-align: center;


    .icon{}
    .txt{
      display: inline-block;
      font-size: 22px;
      font-weight: 700;
      color: #050505;
      margin-left: 10px;
      position: relative;
      top: -2px;

    }

  }
  &__items{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 68px;
  }
  &__item{
    width: 31%;
    position: relative;

    padding: 3px 3px 0;
    .box{
      background: $white;
      height: 100%;
      text-align: center;
      padding: 30px 15px 10px;
    }
    &.violet{
      background: linear-gradient(#48266C 0%, rgba(72, 38, 108, 0)  100%);
      &:before{
        content: "";
        position: absolute;
        width: 223px;
        height: 3px;
        left: 50%;
        background: #48266C;
        top: -83px;
      }
      &:after{
        content: "";
        position: absolute;
        width: 3px;
        height: 83px;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: -83px;
        background: #48266C;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        &:before{
          width: 180px;
        }
      }
    }
    &.green{
      background: linear-gradient(#006C2F 0%, rgba(72, 38, 108, 0)  100%);
      &:after{
        content: "";
        position: absolute;
        width: 3px;
        height: 65px;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: -65px;
        background: #006C2F;
      }
    }
    &.orange{
      background: linear-gradient(#F37B17 0%, rgba(72, 38, 108, 0)  100%);
      &:before{
        content: "";
        position: absolute;
        width: 213px;
        height: 3px;
        right: 50%;
        background: #F37B17;
        top: -83px;
      }
      &:after{
        content: "";
        position: absolute;
        width: 3px;
        height: 83px;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: -83px;
        background: #F37B17;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        &:before{
          width: 170px;
        }
      }
    }
  }
  &__icon{
    margin-bottom: 20px;
  }
  &__title{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  &__desc{}
  @media (max-width: 991px) {
    &__logo{
      padding-top: 20px;
      margin-bottom: 25px;
    }
    &__items{
      padding-top: 0;
    }
    &__item{
      width: 100%;

      &:before{
        opacity: 0;
      }
      &:after{
        opacity: 0;
      }
      .box{
        padding-bottom: 40px;
      }
    }
  }
}

.text-center{
  text-align: center;
}

.about-step{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__item{
    width: 18%;
    cursor: pointer;
    &.grey{

      .items{
        &:before{
          background: #717171;
        }
        &:after{
          background: #717171;
        }
      }
      .item{
        &:before{
          background: #717171;
        }
      }
      .about-step__title{
        background: linear-gradient(41deg, #727272 22.1%, rgba(113, 113, 113, 0.19) 66.45%);
        box-shadow: 0px 28px 50px rgba(160, 160, 160, 0.4);

      }
      @media (min-width: 992px) {

        &:hover, &.active{
          .about-step__desc{
            opacity: 1;
          }
        }
        .about-step__desc{
          position: relative;
          left: 50%;
          opacity: 0;
          .items{
            flex-wrap: nowrap;
          }
        }
      }
    }
    &.orange{
      .about-step__title{
        background: linear-gradient(41.14deg, #956100 12.79%, rgba(255, 220, 101, 0.6) 66.39%);
        box-shadow: 0px 28px 50px rgba(249, 223, 143, 0.4);

      }
      .items{
        &:before{
          background: #956100;
        }
        &:after{
          background: #956100;
        }
      }
      .item{
        &:before{
          background: #956100;
        }
      }
      @media (min-width: 992px) {
        &:hover, &.active{
          .about-step__desc{
            opacity: 1;
          }
        }
        .about-step__desc{
          position: relative;
          left: 50%;
          opacity: 0;
          .items{
            flex-wrap: nowrap;
          }
        }
      }
    }
    &.blue{
      .about-step__title{
        background: linear-gradient(41.15deg, #002377 0%, #44C3FB 66.3%);
        box-shadow: 0px 28px 50px rgba(61, 180, 239, 0.4);

      }
      .items{
        &:before{
          background: #002377;
        }
        &:after{
          background: #002377;
        }
      }
      .item{
        &:before{
          background: #002377;
        }
      }
      @media (min-width: 992px) {
        &:hover, &.active{
          .about-step__desc{
            opacity: 1;
          }
        }
        .about-step__desc{
          position: relative;
          left: 50%;
          opacity: 0;
          .items{
            flex-wrap: nowrap;
          }
        }
      }
    }
    &.green{
      .about-step__title{
        background: linear-gradient(41.14deg, #004329 0.53%, #B2EA84 66.9%);
        box-shadow: 0px 28px 50px rgba(144, 202, 114, 0.5);

      }
      .items{
        &:before{
          background: #004329;


        }
        &:after{
          background: #004329;
        }
      }
      .item{
        &:before{
          background: #004329;
        }
      }

      @media (min-width: 992px) {
        .items{
          &:before{
            background: #004329;
            left: 201%;
          }
          &:after{
            background: transparent;
          }
        }
        &:hover, &.active{
          .about-step__desc{
            opacity: 1;
          }
        }
        .about-step__desc{
          position: relative;
          left: -153%;
          opacity: 0;
          .items{
            flex-wrap: nowrap;
          }
        }
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        .items{
          &:before{
            background: #004329;
            left: 244%;
          }
          &:after{
            background: #004329;
          }
        }
        &:hover{
          .about-step__desc{
            opacity: 1;
          }
        }
        .about-step__desc{
          position: relative;
          left: -201%;
          opacity: 0;
          .items{
            flex-wrap: nowrap;
          }
        }
      }
      @media (max-width: 991px) {

      }
    }
    &.violet{
      .about-step__title{
        background: linear-gradient(40.92deg, #470053 0%, rgba(230, 87, 255, 0.61) 66.22%);
        box-shadow: 0px 28px 50px rgba(226, 131, 243, 0.4);

      }
      .items{
        &:before{
          background: #470053;
        }
        &:after{
          background: #470053;
        }
      }
      .item{
        &:before{
          background: #470053;
        }
      }
      @media (min-width: 992px) {
        .items {
          &:before {
            left: 294%;

          }
        }
        &:hover, &.active{
          .about-step__desc{
            opacity: 1;
          }
        }
        .about-step__desc{
          position: relative;
          left: -248%;
          opacity: 0;
          .items{
            flex-wrap: nowrap;
          }
        }
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        .items {
          &:before {
            left: 357%;

          }
        }
        &:hover, &.active{
          .about-step__desc{
            opacity: 1;
          }
        }
        .about-step__desc{
          position: relative;
          left: -319%;
          opacity: 0;
          .items{
            flex-wrap: nowrap;
          }
        }
      }
    }

  }
  &__title{
    text-align: center;
    padding: 30px 0;
    position: relative;
    border-radius: 28px;
    height: 221px;
    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
    .icon{
      position: relative;
      z-index: 2;

    }
    .text{
      color:$white;
      position: relative;
      z-index: 2;

    }
  }
  &__desc{
    width: 100%;
    position: absolute;


    .items{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      position: relative;
      margin-top: 30px;
      &:before{
        content: "";
        position: absolute;
        width: 1px;
        height: 55px;
        top: 0;
        left: 0;
        transform: rotate(15deg);
      }
      &:after{
        content: "";
        position: absolute;
        width: 20px;
        height: 1px;
        top: 53px;
        left: -7px;
      }
      .item{
        padding: 0 15px;
        position: relative;
        &:before{
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          top: 53px;
          left: 0;
        }
        .icon{
          text-align: center;
          height: 43px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          &.ar{
            width: 38px;
            img{

            }

          }
          &.vr{
            width: 52px;
            img{

            }
          }

        }
        .title{
          white-space: nowrap;
        }
      }
    }
  }
  @media (max-width: 991px) {
    &__item{
      width:100%;
      margin-bottom: 25px;
      &.active{
        .about-step__desc{
          display: block;
        }
      }
    }
    &__desc{
      position: relative;
      display: none;
      opacity: 1;
      margin-left: 15px;
      .items{
        position: relative;
        .item{
          margin-bottom: 10px;
        }
      }
    }
    &__title{
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      height: auto;
      padding: 0;
      br{
        display: none;
      }
      .text{

      }
    }
  }
  @media (max-width: 529px) {

    &__title{
      padding: 20px 0;
      .icon{
        width: 60px;
        height: 60px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .text{
        line-height: 1.2;
        width: calc(100% - 80px);
      }
    }
  }

}

.about-technology{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__box{
    width: 45%;
  }
  &__item{
    margin-bottom: 20px;
  }
  &__title{
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 7px;
  }
  &__desc{
    font-size: 16px;
  }
  @media (max-width: 767px) {
    &__box{
      width: 100%;

    }
  }
}

.about-clients{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0 -15px;
  &__item{
    margin:  0 15px;
  }
  img{
    filter: grayscale(100%);
    transition: all 0.3s ease;
    &:hover{
      filter: grayscale(0%);
    }
  }
  @media (max-width: 991px) {
    &__img{
      height: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.tabs-content{
  margin-bottom: 60px;
  &:last-child{
    margin-bottom: 0;
  }
  @media (max-width: 767px) {

  }
}

.mt27{
  margin-top: 29px;
}

.services-detail__item{
  ul{
    li{
      a{
        pointer-events: none;
      }
    }
  }

}
.services-header__back{
  &:hover{
    color:#050505;
  }
}
.footer-main-home{
  .footer__lang{
    text-align: center;
  }

}


.services-detail.vr{
  .services-detail__tabs{
    ul li a{
      background: linear-gradient(44.09deg,#002377,#44c3fb);
      color:#c2eaff;
    }
  }
}
.services-detail.ar{
  .services-detail__tabs{
    ul li a{
      background: linear-gradient(44.09deg,#956100,rgba(255,220,101,.6));
      color:#feffc2;
    }
  }
}
.services-detail.dr{
  .services-detail__tabs{
    ul li a{
      background: linear-gradient(44.09deg,#004329,#b2ea84);
      color:#dbffd8;
    }
  }
}

.services-detail.web{
  .services-detail__tabs{
    ul li a{
      background: linear-gradient(44.09deg,#470053,rgba(230,87,255,.61));
      color:#ffe8fe;
    }
  }

}


.services-detail.about{
  .services-detail__tabs{
    ul li a{
      background: linear-gradient(44.09deg,#898989,#585858 .01%,hsla(0,0%,91%,.56));
      color:#fff;
    }
  }
}

@media (max-width: 991px) {
  .morder-1{
    order:1;
  }
  .morder-2{
    order:2;

  }
  .morder-4{
    order:4;
  }
  .morder-3{
    order:3;
  }
  .morder{
    margin-top: 2px !important;
    margin-bottom: 25px;
  }
}
