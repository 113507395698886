/*$PTSerif:'PT Serif', serif;
$OpenSans:'Open Sans', sans-serif;*/

@font-face {
  font-family: 'Visby CF';
  src: url('../fonts/VisbyCF/VisbyCF-Bold.eot');
  src: local('../fonts/VisbyCF/VisbyCF-Bold'), local('VisbyCF-Bold'),
  url('../fonts/VisbyCF/VisbyCF-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/VisbyCF/VisbyCF-Bold.woff') format('woff'),
  url('../fonts/VisbyCF/VisbyCF-Bold.woff2') format('woff2'),
  url('../fonts/VisbyCF/VisbyCF-Bold.ttf') format('truetype');
  font-weight: bold;//700
  font-style: normal;
}


@font-face {
  font-family: 'Visby CF';
  src: url('../fonts/VisbyCF/VisbyCF-DemiBold.eot');
  src: local('../fonts/VisbyCF/VisbyCF-DemiBold'), local('VisbyCF-DemiBold'),
  url('../fonts/VisbyCF/VisbyCF-DemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/VisbyCF/VisbyCF-DemiBold.woff') format('woff'),
  url('../fonts/VisbyCF/VisbyCF-DemiBold.woff2') format('woff2'),
  url('../fonts/VisbyCF/VisbyCF-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
//VisbyCF-ExtraBold
@font-face {
  font-family: 'Visby CF';
  src: url('../fonts/VisbyCF/VisbyCF-ExtraBold.eot');
  src: local('../fonts/VisbyCF/VisbyCF-ExtraBold'), local('VisbyCF-ExtraBold'),
  url('../fonts/VisbyCF/VisbyCF-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/VisbyCF/VisbyCF-ExtraBold.woff') format('woff'),
  url('../fonts/VisbyCF/VisbyCF-ExtraBold.woff2') format('woff2'),
  url('../fonts/VisbyCF/VisbyCF-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
//VisbyCF-Heavy
@font-face {
  font-family: 'Visby CF';
  src: url('../fonts/VisbyCF/VisbyCF-Heavy.eot');
  src: local('../fonts/VisbyCF/VisbyCF-Heavy'), local('VisbyCF-Heavy'),
  url('../fonts/VisbyCF/VisbyCF-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../fonts/VisbyCF/VisbyCF-Heavy.woff') format('woff'),
  url('../fonts/VisbyCF/VisbyCF-Heavy.woff2') format('woff2'),
  url('../fonts/VisbyCF/VisbyCF-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
//VisbyCF-Light
@font-face {
  font-family: 'Visby CF';
  src: url('../fonts/VisbyCF/VisbyCF-Light.eot');
  src: local('../fonts/VisbyCF/VisbyCF-Light'), local('VisbyCF-Light'),
  url('../fonts/VisbyCF/VisbyCF-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/VisbyCF/VisbyCF-Light.woff') format('woff'),
  url('../fonts/VisbyCF/VisbyCF-Light.woff2') format('woff2'),
  url('../fonts/VisbyCF/VisbyCF-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
//VisbyCF-Medium
@font-face {
  font-family: 'Visby CF';
  src: url('../fonts/VisbyCF/VisbyCF-Medium.eot');
  src: local('../fonts/VisbyCF/VisbyCF-Medium'), local('VisbyCF-Medium'),
  url('../fonts/VisbyCF/VisbyCF-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/VisbyCF/VisbyCF-Medium.woff') format('woff'),
  url('../fonts/VisbyCF/VisbyCF-Medium.woff2') format('woff2'),
  url('../fonts/VisbyCF/VisbyCF-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
//VisbyCF-Thin
@font-face {
  font-family: 'Visby CF';
  src: url('../fonts/VisbyCF/VisbyCF-Thin.eot');
  src: local('../fonts/VisbyCF/VisbyCF-Thin'), local('VisbyCF-Thin'),
  url('../fonts/VisbyCF/VisbyCF-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/VisbyCF/VisbyCF-Thin.woff') format('woff'),
  url('../fonts/VisbyCF/VisbyCF-Thin.woff2') format('woff2'),
  url('../fonts/VisbyCF/VisbyCF-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "PFDinCondensed";
  src: url("../fonts/PFDinCondensed/PFDinCondensedRegular/PFDinCondensedRegular.eot");
  src: url("../fonts/PFDinCondensed/PFDinCondensedRegular/PFDinCondensedRegular.eot?#iefix")format("embedded-opentype"),
  url("../fonts/PFDinCondensed/PFDinCondensedRegular/PFDinCondensedRegular.woff") format("woff"),
  url("../fonts/PFDinCondensed/PFDinCondensedRegular/PFDinCondensedRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "PFDinCondensed";
  src: url("../fonts/PFDinCondensed/PFDinCondensedMedium/PFDinCondensedMedium.eot");
  src: url("../fonts/PFDinCondensed/PFDinCondensedMedium/PFDinCondensedMedium.eot?#iefix")format("embedded-opentype"),
  url("../fonts/PFDinCondensed/PFDinCondensedMedium/PFDinCondensedMedium.woff") format("woff"),
  url("../fonts/PFDinCondensed/PFDinCondensedMedium/PFDinCondensedMedium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "PFDinCondensed";
  src: url("../fonts/PFDinCondensed/PFDinCondensedLight/PFDinCondensedLight.eot");
  src: url("../fonts/PFDinCondensed/PFDinCondensedLight/PFDinCondensedLight.eot?#iefix")format("embedded-opentype"),
  url("../fonts/PFDinCondensed/PFDinCondensedLight/PFDinCondensedLight.woff") format("woff"),
  url("../fonts/PFDinCondensed/PFDinCondensedLight/PFDinCondensedLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "PFDinCondensed";
  src: url("../fonts/PFDinCondensed/PFDinCondensedThin/PFDinCondensedThin.eot");
  src: url("../fonts/PFDinCondensed/PFDinCondensedThin/PFDinCondensedThin.eot?#iefix")format("embedded-opentype"),
  url("../fonts/PFDinCondensed/PFDinCondensedThin/PFDinCondensedThin.woff") format("woff"),
  url("../fonts/PFDinCondensed/PFDinCondensedThin/PFDinCondensedThin.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "PFDinCondensed";
  src: url("../fonts/PFDinCondensed/PFDinCondensedLightItalic/PFDinCondensedLightItalic.eot");
  src: url("../fonts/PFDinCondensed/PFDinCondensedLightItalic/PFDinCondensedLightItalic.eot?#iefix")format("embedded-opentype"),
  url("../fonts/PFDinCondensed/PFDinCondensedLightItalic/PFDinCondensedLightItalic.woff") format("woff"),
  url("../fonts/PFDinCondensed/PFDinCondensedLightItalic/PFDinCondensedLightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "PFDinCondensed";
  src: url("../fonts/PFDinCondensed/PFDinCondensedThinItalic/PFDinCondensedThinItalic.eot");
  src: url("../fonts/PFDinCondensed/PFDinCondensedThinItalic/PFDinCondensedThinItalic.eot?#iefix")format("embedded-opentype"),
  url("../fonts/PFDinCondensed/PFDinCondensedThinItalic/PFDinCondensedThinItalic.woff") format("woff"),
  url("../fonts/PFDinCondensed/PFDinCondensedThinItalic/PFDinCondensedThinItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNovaThin/ProximaNovaT-Thin.eot');
  src: local('../fonts/ProximaNova/ProximaNovaThin/Proxima Nova Thin'), local('ProximaNovaT-Thin'),
  url('../fonts/ProximaNova/ProximaNovaThin/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaThin/ProximaNovaT-Thin.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaThin/ProximaNovaT-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNovaRegular/ProximaNova-Regular.eot');
  src: local('../fonts/ProximaNova/ProximaNovaRegular/Proxima Nova Regular'), local('ProximaNova-Regular'),
  url('../fonts/ProximaNova/ProximaNovaRegular/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaRegular/ProximaNova-Regular.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaRegular/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNovaLight/ProximaNova-Light.eot');
  src: local('../fonts/ProximaNova/ProximaNovaLight/Proxima Nova Light'), local('ProximaNova-Light'),
  url('../fonts/ProximaNova/ProximaNovaLight/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaLight/ProximaNova-Light.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaLight/ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNovaBold/ProximaNova-Bold.eot');
  src: local('../fonts/ProximaNova/ProximaNovaBold/Proxima Nova Bold'), local('ProximaNova-Bold'),
  url('../fonts/ProximaNova/ProximaNovaBold/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaBold/ProximaNova-Bold.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaBold/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNovaSemibold/ProximaNova-Semibold.eot');
  src: local('../fonts/ProximaNova/ProximaNovaSemibold/Proxima Nova Semibold'), local('ProximaNova-Semibold'),
  url('../fonts/ProximaNova/ProximaNovaSemibold/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaSemibold/ProximaNova-Semibold.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaSemibold/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova Extrabld';
  src: url('../fonts/ProximaNova/ProximaNovaExtraBold/ProximaNova-Extrabld.eot');
  src: local('Proxima Nova Extrabold'), local('ProximaNova-Extrabld'),
  url('../fonts/ProximaNova/ProximaNovaExtraBold/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaExtraBold/ProximaNova-Extrabld.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaExtraBold/ProximaNova-Extrabld.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova/ProximaNovaBlack/ProximaNova-Black.eot');
  src: local('Proxima Nova Black'), local('ProximaNova-Black'),
  url('../fonts/ProximaNova/ProximaNovaBlack/ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNovaBlack/ProximaNova-Black.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNovaBlack/ProximaNova-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

$VisbyCf:'Visby CF', Arial sans-serif;
$PFDinCondensed:'PFDinCondensed',Arial sans-serif;



$ProximaNova:'Proxima Nova';


@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i&subset=cyrillic');

$PTSans:'PT Sans', sans-serif;
$TTNorms:'TT Norms', Arial sans-serif;

$fontBase:$TTNorms;




