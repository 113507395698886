.header{
  padding: 28px 0;

  &__box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    position: relative;
  }
  .logo{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    .icon{
      height: 60px;

    }
    .title{
      font-size: 22px;
      font-weight: bold;
      color:#050505;
      margin-left: 10px;
      position: relative;
      top: -2px;

    }
  }
  .menu-toggle{
    margin-top: -4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    .text{
      font-size: 15px;
      font-weight: bold;
      color:#050505;
      margin-right: 14px;
    }

  }
  &.fix{
    .menu-box{
      width: 65%;
    }
  }
  .title2{
    left: 155px;
    font-size: 22px;
    font-weight: bold;
    color:#050505;
    top: 15px;
    position: absolute;
    display: none;

  }
  @media (min-width: 1200px) {
    width: 100%;
    background: $white;
    position: fixed;
    z-index: 21;
    top: 0;
    .title2{
      display: block;
      opacity: 0;
    }
    &.fix{
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+78,ffffff+78,ffffff+78,ffffff+100&1+0,0.99+81,0+100 */
      background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.99) 78%, rgba(255,255,255,0.99) 81%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.99) 78%,rgba(255,255,255,0.99) 81%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.99) 78%,rgba(255,255,255,0.99) 81%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
      padding-bottom: 70px;
      .title2{
        opacity: 1;

      }
      .logo{
        .icon,.title{
          opacity: 0;

        }
      }

    }
  }
  @media (max-width: 767px) {
    &__box{
      height: 48px;
    }
    .logo{
      .icon{
        img{
          height: 48px;
        }


      }
      .title{
        font-size: 17px;
        top: -9px;
      }
    }

  }
}

.menu-toggle{
  width: 70px;
  position: absolute;
  right: 0;
  top: 7px;
  //background: #fff;
  z-index: 3;
  cursor: pointer;

  .icon{
    width: 16px;
    position: relative;
    img{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .up{
    opacity:0;
  }
  .down{
    opacity: 1;
  }
}

.menu-box{
  position: relative;
  width: calc(100% - 250px);
  margin: 0 0 0 auto;
  height: 24px;
  &.menu-active{
    .menu-toggle{
      width: 70px;//
      .text{
        //opacity: 0;
        //width: 0;
      }
      .up{
        opacity:1;
      }
      .down{
        opacity: 0;
      }
    }
    .menu-list{
      width: 100%;
      right: 80px;//41
      li{
        &.active{
          transform: scale(1);
          //transform: translateX(100px);
          //transform: translateX(0px);
          opacity: 1;
         /* &:nth-child(1){
            transform: translateX(0px);
            opacity: 1;
          }
          &:nth-child(2){
            transform: translateX(0px);
            opacity: 1;
          }
          &:nth-child(3){
            transform: translateX(0px);
            opacity: 1;
          }
          &:nth-child(4){
            transform: translateX(0px);
            opacity: 1;
          }
          &:nth-child(5){
            transform: translateX(0px);
            opacity: 1;
          }*/
        }
      }
    }
  }

  @media (min-width: 992px) {
    position: absolute;
    right: 0;
  }
  @media (max-width: 991px) {
    &.active{
      .menu-list{
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  @media (max-width: 767px) {
    top: -8px;
    width: calc(100% - 190px);
    &.active{
      .menu-list{
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
.menu-list{

  @media (min-width: 992px) {
    position: absolute;
    right: 80px;
    width: 0%;
    overflow: hidden;
    text-align: right;
    transition: width 0.5s ease;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;

    li{
      position: relative;
      display: inline-block;
      font-size: 16px;

      padding: 0 20px;
      background: $white;
      //transform: translateX(100px);
      transform: scale(0.2);
      opacity: 0;
      height: 38px;
      a{
        white-space: nowrap;
        font-size: 15px;
        line-height: 24px;
        font-weight: bold;
        letter-spacing: -0.2px;
        color: #050505;
        position: relative;
        padding: 10px 0;
        &:hover{
          &:before{
            width: 100%;
          }

        }
        &:before{
          content: "";
          position: absolute;
          width: 0%;
          height: 2px;
          left: 0;
          right: 0;
          margin: auto;
          bottom: 5px;//-2
          transition: width 0.3s ease;
        }

        &.vr-menu{
          &:before{
            background: linear-gradient(44.09deg, #002377 0%, #44C3FB 100%);
          }

        }
        &.ar-menu{
          &:before{
            background: linear-gradient(44.09deg, #956100 0%, rgba(255, 220, 101, 0.6) 100%);
          }
        }
        &.dr-menu{
          &:before{
            background: linear-gradient(44.09deg, #004329 0%, #B2EA84 100%);

          }
        }
        &.web-menu{
          &:before{
            background: linear-gradient(44.09deg, #470053 0%, rgba(230, 87, 255, 0.61) 100%);

          }
        }
        &.about-menu{
          &:before{
            background: linear-gradient(44.09deg, #898989 0%, #585858 0.01%, rgba(232, 232, 232, 0.56) 100%);

          }
        }
      }

      &:nth-child(1){
        //transform: scale(1);
        //transform: translateX(100px);
        transition: all 0.5s ease 0.6s;
        opacity: 0;

      }
      &:nth-child(2){

        //transform: scale(1);
        //transform: translateX(100px);
        transition: all 1s ease 0.5s;
        opacity: 0;
      }
      &:nth-child(3){

        //transform: scale(1);
        //transform: translateX(100px);
        transition: all 0.5s ease 0.4s;
        opacity: 0;
      }
      &:nth-child(4){

        //transform: scale(1);
        //transform: translateX(100px);
        transition: all 0.5s ease 0.3s;
        opacity: 0;
      }
      &:nth-child(5){

        //transform: scale(1);
        //transform: translateX(100px);
        transition: all 0.5s ease 0s;
        opacity: 0;
      }
    }
  }
  @media (max-width: 991px) {
    pointer-events: none;
    opacity: 0;
    position: fixed;
    left: 0;
    width: 100%;
    text-align: center;
    top: 80px;
    background: $white;
    height: 100vh;
    z-index: 11;
    transition:  all 0.5s ease;
    li{
      a{
        display: block;
        padding: 31px 10px;
        font-size: 26px;
        line-height: 32px;
        font-weight: bold;
        letter-spacing: -0.35px;
        color: #050505;
      }
    }
  }
  @media (max-height: 550px) and (orientation:landscape)  {
    li{
      a{
        font-size: 24px;
        line-height: 28px;
        padding: 18px 10px;
      }
    }
  }
}




