//colors
$white:#fff;

$red:#ce4238;
$black-lite:#151515;
/*old*/
$grey:#6d6d6d;//+ footer

$grey-lite:#9c9c9c; //+ крошки ++
$black:#000;//+
//+

//$green-lite:#f3f6f1;//bg header-top

$zoloto:#cea64e;//+

$green-lite:#5c9543;
$green:#5c9543;//logo text
$green-dark:#3d662a;//btn home slider

$blue:blue;

.white{
  color:$white;
}
.black{
  color:$black;
}

.grey{
  color:$grey;
}

.bg-greenLite{
  //background: $green-lite;
}
.bg-white{
  background: $white;
}
.bg-greylite{
  background: #fafafa;
}
.bg-black{
  background: $black;
}
.bg-black-lite{
  background: #252422;
}
//bg-colors
@import "mixin";
@import "fonts";
@import "libs/aos/aos";
@import "my-aos";
@import "reboot";

@import "animate";


@import "libs/fansybox/jquery.fancybox";
@import "libs/swiper/swiper";
@import "libs/slick/slick.scss";
@import "libs/slick/slick-theme.scss";
@import "libs/twentytwenty";
@import "menu";
@import "menu1.sass";
@import "services";
@import "new";
@import "libs/mediaelementplayer.min";
//@import "libs/jquery-ui/jquery-ui.theme";

@import "preloader";
//blocks


//page

@import "home";

@import "jquery.mCustomScrollbar";




//standart
//+

html, body{
  max-width: 100%;
  overflow-x: hidden !important;
  scroll-behavior: smooth;



}

body{
  color:$black;
  font-family: $VisbyCf;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  background: $white;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  /*overflow-y: hidden;
  max-height: 100vh;*/
  //-webkit-font-smoothing: subpixel-antialiased;
  //-webkit-font-smoothing: antialiased
}
@media (min-width: 1200px) {
  .page-home{
    .pjax-container{
      margin-top: 116px;
    }
    .home-sl{

    }
    .footer-main-home{

    }

  }
}
@media (min-width: 1280px) {
 /* .page-home{
      width: 100wh;


      background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
      background-size: 1000% 400%;
      -webkit-animation: Gradient 15s ease infinite;
      -moz-animation: Gradient 15s ease infinite;
      animation: Gradient 15s ease infinite;



    @-webkit-keyframes Gradient {
      0% {
        background-position: 0% 50%
      }
      50% {
        background-position: 100% 50%
      }
      100% {
        background-position: 0% 50%
      }
    }

    @-moz-keyframes Gradient {
      0% {
        background-position: 0% 50%
      }
      50% {
        background-position: 100% 50%
      }
      100% {
        background-position: 0% 50%
      }
    }

    @keyframes Gradient {
      0% {
        background-position: 0% 50%
      }
      50% {
        background-position: 100% 50%
      }
      100% {
        background-position: 0% 50%
      }
    }
  }*/
  .body-phome{
    position: relative;
    max-width: 1366px;
    margin: 0 auto;
    box-shadow: -2px 10px 28px -11px rgba(0,0,0,.75);
    padding: 1px 23px 15px 0;
    background: #fff;
    overflow: hidden;
    &.ms-active{
      .header{
        background: #fff;
        &.fix{
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+78,ffffff+78,ffffff+78,ffffff+100&1+0,0.99+81,0+100 */
          background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.99) 78%, rgba(255,255,255,0.99) 81%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.99) 78%,rgba(255,255,255,0.99) 81%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.99) 78%,rgba(255,255,255,0.99) 81%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */

        }

      }
    }
    &.page-services{
      box-shadow: none;
      max-width: 100%;
      padding: 0;
    }

    .header{
      left: 0;
      background: transparent;
      &.fix{
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+78,ffffff+78,ffffff+78,ffffff+100&1+0,0.99+81,0+100 */
        background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.99) 78%, rgba(255,255,255,0.99) 81%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.99) 78%,rgba(255,255,255,0.99) 81%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.99) 78%,rgba(255,255,255,0.99) 81%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
      }
    }
    &:before{
      content: "";
      position: absolute;
      width: 100%;
      max-width: 1366px;

      height: 100%;
      margin: auto;
      left: 0;
      right: 0;


    }
    &:after{

    }
  }
}
@media (min-width: 1366px) {

}
*{
  outline: none;
  &:focus, &:hover, &:active{
    outline: none;
  }
}
.btn.focus, .btn:focus{
  box-shadow: none;
}

input, textarea{
  outline: none;
  border:none;
  border-radius: 0;
}
label{
  margin-bottom: 0;
}
ul{
  padding: 0;
  margin: 0;
  li{
    list-style: none;
  }
}
p{margin-bottom: 0}
a{
  //display: block;
  &:hover, &:focus{
    outline: none;
    text-decoration: none;
  }
}
img{
  max-width: 100%;
}
.slick-list{
  width: 100%;
}

.clear{
  clear:both;
}
.bt{
  border-top: 1px solid #e7ece5;
}
.bb{
  border-bottom: 1px solid #e7ece5;
}

h2, .h2{
  font-size: 48px;
  font-weight: bold;
  color:$black-lite;
  margin-bottom: 34px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-left: -3px;
  &.textmormal{
    text-transform: none;
  }
  &.fw-normal{
    font-weight: normal;
    font-size: 46px;
  }
  & + .h4{
    margin-top: 39px;
  }
  @media (max-width:991px) {
    font-size: 28px;
    margin-left: -2px;
    margin-bottom: 27px;
    &.fw-normal{
      font-weight: normal;
      font-size: 28px;
    }
    & + .h4{
      margin-top: 28px;
    }
  }
}
h3, .h3{
  font-size: 30px;
  font-weight: 500;
  color:$black-lite;
  margin-bottom: 31px;

  @media (max-width:991px) {
    font-size: 20px;
    margin-bottom: 13px;
  }
}
h4, .h4{
  font-size: 24px;
  line-height: 36px;
  font-weight: 300;
  color:#323232;
  margin-bottom: 22px;

  @media (max-width:991px) {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 13px;
  }
}


.flex-between{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.flex-around{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.flex-jcenter{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bg-image{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;


}



@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}










/*body.fancybox-active {
  overflow: hidden!important;
}*/
.compensate-for-scrollbar {
  margin-right: 0px !important;
}


.footer__lang{
  width: 33%;
  text-align: left;
  a{
    //display: none;
    &.actives{
      display: inline-block;
    }

  }
  @media (min-width: 992px) {
    display: inline-block;
    width: 108px;
    position: absolute;
    left: 1px;
    right: 0;
    margin: auto;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    text-align: center;
    //padding-right: 18%;
  }
  @media (max-width: 991px) {
    text-align: right;
    width: auto;
  }
  @media (max-width: 767px) {
    text-align: right;
    width: 48%;
  }
  @media (max-width: 412px) {

    text-align: right;
  }
}
.footer.footer-main-home .footer__lang{
  @media (min-width: 992px) and (max-width: 1199px) {
    text-align: center;
    padding-right: 0%;
  }
}

.area-logo{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  &__icon{
    width: 60px;
    height: 60px;
  }
  &__title{
    font-size: 22px;
    font-weight: 700;
    color: #050505;
    margin-left: 10px;
    position: relative;
    top: -2px;
  }
  &__subtitle{
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #858585;
    margin-left: 10px;
    position: relative;
    top: -2px;
  }
}

.services-pagination__contacts{
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  min-height: 19px;
  top: 14px;
  font-size: 15px;
  font-weight: 600;
  color: #050505;
  opacity: .6;
  border: 1px solid #050505;
  text-align: center;
  width: 111px;
  margin: auto;
  border-radius: 12px;
  padding: 0 10px 3px;
  transition: all 0.3s ease;
  &:hover{
    opacity: 0.8;
    color: #050505;
  }
  @media (max-width: 474px) {
    display: none;
  }
}
