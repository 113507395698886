@keyframes radius {
  0% {
    border-radius: 28px;
  }
  100% {
    border-radius: 100px 0 0 100px;
  }
}
.page-services{
  padding-bottom: 40px;
}
.page-home{
  &.ms-active{
    height: auto;
    .home-sl{
      .swiper-arrows-home{
        display: none;
      }
    }
    .footer-main-home{
      display: none;
    }

    .main-home{
      height: auto;
    }
    .header-pages{
      display: none;
    }
    .main-services{
      .services-header{
        display: none;
      }
    }

  }

}
.swiper-wrapper{
  &.none{
    opacity: 0;
  }
}
.home-sl{
  &.none{
   display: none;
  }
}
/*.home-sl, .main-home{
  &.none{
    display: none;
  }
}*/
.services-header.fix{
  position: fixed;
  z-index: 5;
  top: 79px;
}
.services-detail{
  &.hfix{
    //padding-top: 225px;
  }
}
.pheader{
  height: 100vh;
}
.page-services{
  min-height: 101vh;
  &.hfix{
    padding-top: 385px;//260
  }
  &.hfix-one{
    padding-top: 80px;
  }
}
.services-header{
  &.fix{
    >.container{
      position: relative;
      right: 30px;
      top: 15px;
    }
    .services-header__back{
      color:$white;
      &:hover{
        color:$white;
      }
      span{
        &:before{
          background: url("../images/arrow-back-white.png") no-repeat center;

        }
      }
    }

  }
  >.container{
    //position: relative;
    z-index: 10000;
    /* left: 0; */
    //right: 30px;
    margin: 0;
    padding: 0 30px 0 0;
    height: 22px;
    width: auto;
  }
}
.main-services{
  &.bg{
    position: relative;
    &:before{
      content: "";
      background: #fff;
      width: 100%;
      height: 100px;
    }
  }
}
/*.services-header__item{
  transition: height 1s ease;
  &.test{
    height: 75px;
  }
}*/
.page-home{
  height: 100vh;
  overflow: hidden !important;
  &.sd-active{
    overflow-y: auto !important;
  }
}


.vr{
  //background:  linear-gradient(51.34deg, #0492E8 0%, #19E3DD 100%);
  &:before{
    box-shadow: 0px 28px 50px rgba(14, 184, 227, 0.2);
  }
  &:after{
    background:  linear-gradient(51.34deg, #0492E8 0%, #19E3DD 100%);
  }
  &:hover{
    &:before{
      box-shadow: 0px 28px 50px rgba(14, 184, 227, 0.3);
    }

  }
}
.vr-title{
  color:#FFE69C;
}
.ar-title{
  color:#B2E4FF;
}
.dr-title{
  color:#F6FFA4;
}
.web-title{
  color:#C1D3FF;
}
.ar{

  &:before{
    box-shadow: 0px 28px 50px rgba(32, 104, 164, 0.2);
  }
  &:after{
    background: linear-gradient(44.09deg, #1F3A7A 0%, #219CD3 100%);
  }
  &:hover{
    &:before{
      box-shadow: 0px 28px 50px rgba(32, 104, 164, 0.3);
    }

  }
}
.dr{


  &:before{
    box-shadow: 0px 28px 50px rgba(80, 131, 82, 0.2);
  }
  &:after{
    background: linear-gradient(44.09deg, #09422C 0%, #A1CD7C 100%);
  }
  &:hover{
    &:before{
      box-shadow: 0px 28px 50px rgba(80, 131, 82, 0.3);
    }

  }
}
.web{


  &:before{
    box-shadow: 0px 28px 50px rgba(67, 89, 133, 0.2);
  }
  &:after{
    background: linear-gradient(44.09deg, #1F3455 0%, #6980B7 100%);
  }
  &:hover{
    &:before{
      box-shadow: 0px 28px 50px rgba(67, 89, 133, 0.3);
    }
  }
}
.main-home{

  @media (min-width: 768px) {
    .container{
      padding: 0;
    }
  }

}
.home-sl{
  padding-top: 234px;
  padding-bottom: 75px;

  .swiper-container{
    overflow: visible;
    opacity: 0;
    &.swiper-container-horizontal{
      opacity: 1;
    }
  }


  .swiper-slide{
    //width: 25%;
    //max-width: 265px;

    margin-right: 30px;
  }
  &__item{
    padding: 24px 24px 32px;

    border-radius: 28px;
    height: 248px;
    position: relative;
    //width: 25%;
    display: block;

    //width: 100%;
    &.test{

      //width: 100vw !important;
      border-radius: 100px 0 0 100px;
      &:after{
        //height: 320px;
        border-radius: 100px 0 0 100px;
      }
      &:before{
        border-radius: 100px 0 0 100px;
      }

    }
    &:before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 28px;
      transition: all 0.3s ease;
    }
    &:after{
      content: "";
      position: absolute;
      border-radius: 28px;
      width: 100%;
      height: 248px;
      bottom: 0;
      left: 0;
      right: 0;

      transition: all 0.3s ease;
    }
    &:hover{
      &:after{
        height: 320px;
      }
      .home-sl__icon{
        top: -186px;
        //transform: scale(1.2) rotate(-45deg);
        transform: scale(1.2) ;
        .up{
          opacity: 0;
        }
        .down{
          opacity: 1;
        }
      }
      .home-sl__content{
        bottom: 78px;
      }
      .home-sl__more{
        opacity: 1;
      }
    }
  }
  &__icon{
    position: absolute;
    width: 240px;
    height: 240px;
    top: -120px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: 2;
    transition: all 0.3s ease;
    transform: scale(0.65);
    img{
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      transition: all 0.3s ease;
      transform: scale(0.9);
    }
    .up{
      opacity: 1;
    }
    .down{
      opacity: 0;
    }
  }
  &__content{
    position: absolute;
    bottom: 32px;
    z-index: 2;
    transition: all 0.3s ease;
  }
  &__title{
    font-size: 29px;
    font-weight: 800;
    letter-spacing: -0.5px;
    margin-bottom: 6px;
  }
  &__subtitle{
    font-size: 16px;
    font-weight: bold;
    color:$white;
    span{

      font-weight: 600;
      opacity: 0.7;
    }
  }
  &__more{
    font-family: $PFDinCondensed;
    font-size: 18px;
    font-weight: 500;
    color:$white;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: absolute;
    bottom: 32px;
    opacity: 0;
    z-index: 2;
    transition: all 0.3s ease;
    span{
      position: relative;
      padding-right: 12px;
      &:before{
        content: "";
        position: absolute;
        background: url("../images/arrow-white.png") no-repeat center;
        width: 9px;
        height: 14px;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .swiper-slide-active{
    .home-sl__icon{
      transform: scale(1);
    }
  }
  .swiper-arrows-home{
    position: relative;
    text-align: right;
    bottom: -114px;
    @media (min-width: 768px) {
      width: 200px;
      margin: 0 0 0 auto;

    }
  }
  .swiper-arrow-home{
    position: relative;
    right: 0px;

    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    color:#050505;
    cursor: pointer;
    &.swiper-button-disabled{
      opacity: 0.6;
      cursor: none;
      pointer-events: none;
    }
    span{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        background: url("../images/arrow-grey.png") no-repeat center;
        width: 9px;
        height: 15px;
        transform: rotate(360deg);
        opacity: 0.7;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: all 0.3s ease;
      }
    }

  }
  .swiper-button-prev-home{
    span{
      padding-left: 16px;
      &:before{
        left: 0;
        transform: rotate(180deg);
      }
    }
    &:hover{
      span{
        &:before{
          left: -5px;
        }
      }

    }
  }
  .swiper-button-next-home{
    margin-left: 50px;
    span{
      padding-right: 16px;
      &:before{
        right: 0;
      }
    }
    &:hover{
      span{
        &:before{
          right: -5px;
        }
      }

    }
  }
  @media (min-width:768px) and (max-height: 800px) {
    padding-top: 21vh;
  }
  @media (min-width: 1200px) {
    .swiper-arrow-home{
      right: 0px;
    }
    .swiper-wrapper{
      &.none{
        .swiper-slide-active{
          .home-sl__item{
            &:after{
              height: 320px;
            }
            .home-sl__icon{
              top: -186px;
              //transform: scale(1.2) rotate(-45deg);
              transform: scale(1.2) ;
              .up{
                opacity: 0;
              }
              .down{
                opacity: 1;
              }
            }
            .home-sl__content{
              bottom: 78px;
            }
            .home-sl__more{
              opacity: 1;
            }
          }
        }
      }
    }
    .swiper-slide-active{
      .home-sl__item{
        &:after{
          height: 320px;
        }
        .home-sl__icon{
          top: -186px;
          //transform: scale(1.2) rotate(-45deg);
          transform: scale(1.2) ;
          .up{
            opacity: 0;
          }
          .down{
            opacity: 1;
          }
        }
        .home-sl__content{
          bottom: 78px;
        }
        .home-sl__more{
          opacity: 1;
        }
      }
    }



  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .swiper-arrow-home{
      right: 0px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    &__title{
      font-size: 26px;
    }
    .swiper-slide{
      width: 30%;
    }
  }
  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
    height: auto;
    .swiper-slide{
      width: 100%;

    }
    &__item{
      max-width: 260px;
      margin: auto;
    }
    .swiper-arrows-home{
      bottom: 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      text-align: initial;
    }
    .swiper-button-prev-home{
      width: 50%;
    }
    .swiper-button-next-home{
      width: 50%;
      margin-left: 0;
      text-align: right;
    }
  }
  @media (max-height: 550px) and (orientation:landscape)  {

    &__item{
      height: 146px;
      &:after{
        height: 146px;
      }
    }
    &__icon{
      transform: scale(0.5);
    }
    .swiper-arrows-home{
      position: absolute;
      bottom: auto;
      top: -40px;
      width: 100%;
      background: $white;
    }
    .swiper-button-prev-home{
      left: 0;
    }
    .swiper-button-next-home{
      right: 0;
    }
    .swiper-slide-active{
      .home-sl__icon{
        transform: scale(0.6);
      }
    }
  }

}
.main-home{
  height: calc(100vh - 116px - 100px);
  .container, .home-sl, .swiper-container{
    height: 100%;
  }
  @media (max-width: 767px) {
    height: calc(100vh - 100px);
    .container{
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
    .home-sl{
      height: auto;
    }
  }

}

.footer{
  padding: 40px 0;
  &__box{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &__social{
    width: 33.333%;
    a{
      display: inline-block;
      transition: all 0.3s ease;
      img{
        transform: scale(1);
        transition: all 0.3s ease;
      }
      & + a{
        margin-left: 21px;
      }
      &:hover{
        img{
          transform: scale(1.1);
        }
      }
    }
  }
  &__lang{
    width: 33.3333%;
    text-align: center;
    a{
      display: inline-block;
      font-size: 13px;
      font-weight: 500;
      color:#050505;
      text-transform: uppercase;
      position: relative;
      padding-bottom: 2px;
      &:before{
        content: "";
        position: absolute;
        height: 1px;
        background: #050505;
        left: 0;
        bottom: 0;
        width: 0;
        transition: width 0.3s ease;
      }
      & + a{
        margin-left: 11px;
      }
      &.actives, &:hover{
        &:before{
          width: 100%;
        }
      }
    }
  }
  &__copyright{
    font-size: 15px;
    font-weight: 600;
    color:#050505;
    opacity: 0.6;
  }
  &.footer--sr{
    .footer__box{
      justify-content: space-between;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 77px;
    &.footer-main-home{
      padding-left: 0;
      .footer__lang{
        text-align: center;
      }
    }
    &__lang{
      text-align: left;
    }
  }
  @media (max-width: 991px) {
    padding-left: 59px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    &.footer-main-home{
      padding-left: 0;
      .footer__social{
        width: 33.3333%;
      }
      .footer__lang{
        width: 33.3333%;
        text-align: center;
      }
    }
  }
  @media (min-width: 685px) and (max-width: 991px) {
    &__social{
      order: 1;
      width: auto;
    }
    &__lang{
      order: 3;
      width: auto;
    }
    &__copyright{
      order: 2;
    }
  }
  @media (max-width: 767px) {
    padding-left: 59px;
    &__social{
      width: 49%;
    }
    &__lang{
      width: 51%;
      text-align: right;
    }
    &__copyright{
      width: 100%;
      margin-top: 15px;
      text-align: center;
      display: none;
    }
    &.footer-main-home{
      padding: 0;
      position: absolute;
      bottom: 18px;
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    &__box{
      justify-content: space-between;
    }
    &__lang{
      width: auto;
      text-align: right !important;
    }
  }
  @media (max-width: 411px) {

    &__social{
      a+a{
        margin-left: 14px;
      }

    }
    &__lang{
      width: auto;
      text-align: right !important;
    }
  }
  @media (max-width: 370px) {
    padding-left: 0px;
  }
}

.services-pagination{
  position: fixed;
  width: 100%;
  background: $white;
  bottom: 0;
  z-index: 11;
  left: 0px;
  .container{
    position: relative;
    height: 48px;
  }
  &__item{

    font-size: 15px;
    font-weight: 600;
    color:#050505;
    opacity: 0.8;
    position: absolute;
    min-height: 19px;
    top: 14px;
    transition: all 0.3s ease;
    &:hover{
      opacity: 1;
      color:#050505;
      &:before{
        opacity: 1;
      }
    }
    &:before{
      content: "";
      position: absolute;
      background: url("../images/arrow-grey.png") no-repeat center;
      width: 9px;
      height: 15px;
      opacity: 0.8;

      bottom: 0;
      margin: auto;
      transform: rotate(360deg);
      top: 1px;
    }
    &.prev{
      padding-left: 17px;
      left: 15px;
      &:before{
        left: 0;
        transform: rotate(180deg);
      }
    }
    &.next{
      padding-right: 17px;
      right: 15px;
      &:before{
        right: 0;
      }
    }
  }
  @media (max-width: 1199px) {
    left: 0;
    right: 0;
  }
}
.svr{
  padding-bottom: 40px;
}

.item-rar {
  animation: radius 1s;
}

.home-sl{
  .swiper-slide-prev{
    transition: all 1s ease;
    opacity: 0;
  }
}
.home-sl__item{
  &.vr{
    //background:  linear-gradient(51.34deg, #0492E8 0%, #19E3DD 100%);
    &:before{
      box-shadow: 0px 28px 50px rgba(61, 180, 239, 0.4);
    }
    &:after{
      background: linear-gradient(44.09deg, #002377 0%, #44C3FB 100%);


    }
    &:hover{
      &:before{
        box-shadow: 0px 28px 50px rgba(61, 180, 239, 0.4);
      }

    }
    .home-sl__title{
      color: #C2EAFF;
    }
  }
  &.ar{
    //background:  linear-gradient(51.34deg, #0492E8 0%, #19E3DD 100%);
    &:before{
      box-shadow: 0px 28px 50px rgba(249, 223, 143, 0.4);
    }
    &:after{
      background: linear-gradient(44.09deg, #956100 0%, rgba(255, 220, 101, 0.6) 100%);
    }
    &:hover{
      &:before{
        box-shadow: 0px 28px 50px rgba(249, 223, 143, 0.4);
      }
    }
    .home-sl__title{
      color: #FEFFC2;
    }
  }
  &.dr{
    //background:  linear-gradient(51.34deg, #0492E8 0%, #19E3DD 100%);
    &:before{
      box-shadow: 0px 28px 50px rgba(144, 202, 114, 0.5);
    }
    &:after{
      background: linear-gradient(44.09deg, #004329 0%, #B2EA84 100%);
    }
    &:hover{
      &:before{
        box-shadow: 0px 28px 50px rgba(144, 202, 114, 0.5);
      }

    }
    .home-sl__title{
      color: #DBFFD8;
    }
  }
  &.web{
    //background:  linear-gradient(51.34deg, #0492E8 0%, #19E3DD 100%);
    &:before{
      box-shadow: 0px 28px 50px rgba(226, 131, 243, 0.4);
    }
    &:after{
      background: linear-gradient(44.09deg, #470053 0%, rgba(230, 87, 255, 0.61) 100%);
    }
    &:hover{
      &:before{
        box-shadow: 0px 28px 50px rgba(226, 131, 243, 0.4);
      }

    }
    .home-sl__title{
      color: #FFE8FE;
    }
  }
  &.about{
    //background:  linear-gradient(51.34deg, #0492E8 0%, #19E3DD 100%);
    &:before{
      box-shadow: 0px 28px 50px rgba(160, 160, 160, 0.4);
    }
    &:after{
      background: linear-gradient(44.09deg, #898989 0%, #585858 0.01%, rgba(232, 232, 232, 0.56) 100%);
    }
    &:hover{
      &:before{
        box-shadow: 0px 28px 50px rgba(160, 160, 160, 0.4);
      }

    }
    .home-sl__title{
      color: #fff;
    }
  }
}

.preloader-box{
  width: 100%;
  height: 100vh;
}
.body-phome{

  opacity: 0;
  transition: all 1s ease 1s;
}

.page-services{
  .area{
    z-index: -1;
  }
  .services-detail{
    background: #fff;
  }
}
