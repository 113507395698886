.home-anime1{
  background: #eee;
}


//это все для кнопки
/////show-btn-1
@-webkit-keyframes show-btn-1 {
  0% {
    visibility: hidden; }
  50% {
    visibility: hidden; }
  50.1% {
    visibility: visible; }
  100% {
    visibility: visible; } }
@-moz-keyframes show-btn-1 {
  0% {
    visibility: hidden; }
  50% {
    visibility: hidden; }
  50.1% {
    visibility: visible; }
  100% {
    visibility: visible; } }
@-ms-keyframes show-btn-1 {
  0% {
    visibility: hidden; }
  50% {
    visibility: hidden; }
  50.1% {
    visibility: visible; }
  100% {
    visibility: visible; } }
@-o-keyframes show-btn-1 {
  0% {
    visibility: hidden; }
  50% {
    visibility: hidden; }
  50.1% {
    visibility: visible; }
  100% {
    visibility: visible; } }
@keyframes show-btn-1 {
  0% {
    visibility: hidden; }
  50% {
    visibility: hidden; }
  50.1% {
    visibility: visible; }
  100% {
    visibility: visible; }
}

//////slide-bg-1
@-webkit-keyframes slide-bg-1 {
  50% {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1); }
  50.1% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center; }
  100% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0); } }

@-moz-keyframes slide-bg-1 {
  50% {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1); }
  50.1% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center; }
  100% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0); } }

@-ms-keyframes slide-bg-1 {
  50% {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1); }
  50.1% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center; }
  100% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0); } }

@-o-keyframes slide-bg-1 {
  50% {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1); }
  50.1% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center; }
  100% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0); } }

@keyframes slide-bg-1 {
  50% {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1); }
  50.1% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center; }
  100% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0); } }

.slide-effect-btn, [data-aos="slide-effect-btn"] {
  display: inline-block;
  position: relative; }
.slide-effect-btn a, [data-aos="slide-effect-btn"] a {
  visibility: hidden;
  -webkit-animation-duration: 1.2s;
  -moz-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);

}
.slide-effect-btn:after, .slide-effect-btn2 a,
[data-aos="slide-effect-btn"]:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-animation-duration: 1.2s;
  -moz-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86); }

.slide-effect-btn.seb-white:after,
[data-aos="slide-effect-btn"].seb-white:after {
  background-color: #fff; //#fff
}

.slide-effect-btn.seb-fifth:after,
[data-aos="slide-effect-btn"].seb-fifth:after {
  background-color: #f7d584; }

.slide-effect-btn.seb-secondary:after,
[data-aos="slide-effect-btn"].seb-secondary:after {
  background-color: #1b1b22; }


//это для прокрутки нужно
/*.slide-effect-btn a,
.slide-effect-btn.aos-animate a,
[data-aos="slide-effect-btn"].aos-animate a {
  -webkit-animation-name: show-btn-1;
  -moz-animation-name: show-btn-1;
  -ms-animation-name: show-btn-1;
  -o-animation-name: show-btn-1;
  animation-name: show-btn-1; }*/

.slide-effect-btn.aos-animate:after,
[data-aos="slide-effect-btn"].aos-animate:after {
  -webkit-animation-name: slide-bg-1;
  -moz-animation-name: slide-bg-1;
  -ms-animation-name: slide-bg-1;
  -o-animation-name: slide-bg-1;
  animation-name: slide-bg-1; }


//slider
.slick-slider{
  .slick-active{
    .slick-slider__item{
      .left{
        .slide-effect-btn a,.slide-effect-btn.aos-animate a,
        [data-aos="slide-effect-btn"].aos-animate a {
          -webkit-animation-name: show-btn-1;
          -moz-animation-name: show-btn-1;
          -ms-animation-name: show-btn-1;
          -o-animation-name: show-btn-1;
          animation-name: show-btn-1;
        }

        .slide-effect-btn:after,
        [data-aos="slide-effect-btn"].aos-animate:after {
          -webkit-animation-name: slide-bg-1;
          -moz-animation-name: slide-bg-1;
          -ms-animation-name: slide-bg-1;
          -o-animation-name: slide-bg-1;
          animation-name: slide-bg-1; }
      }
    }

  }
  .slick-slider__item{
    display: flex !important;
    .left{
      flex:0 0 50%;
    }
    .right{
      flex:0 0 50%;
    }
  }
}

//hiover
.knopka-cont{
  width: 600px;
  background: rgba(0,0,0,0.2);
  margin-left:500px;

  &:hover{
    .slide-effect-btn a,.slide-effect-btn.aos-animate a,
    [data-aos="slide-effect-btn"].aos-animate a {
      -webkit-animation-name: show-btn-1;
      -moz-animation-name: show-btn-1;
      -ms-animation-name: show-btn-1;
      -o-animation-name: show-btn-1;
      animation-name: show-btn-1;
    }

    .slide-effect-btn:after,
    [data-aos="slide-effect-btn"].aos-animate:after {
      -webkit-animation-name: slide-bg-1;
      -moz-animation-name: slide-bg-1;
      -ms-animation-name: slide-bg-1;
      -o-animation-name: slide-bg-1;
      animation-name: slide-bg-1; }
  }
}
.knopka{
  //width: 300px;
  //height: 60px;
  //outline: 1px solid green;
 // margin-left: 100px;
  //margin-bottom: 50px;
  position: relative;

  a, span{
    display: block;
    background: #fff;

    color: #000;


    //transition-property: transform;

    //opacity: 0;

  }

}


/* для картинки */
.hcsr-container {
  //float: left;
  width: 33.3333333%;
  height: 1px;
  overflow: hidden;
  padding-bottom: 68.8%;
  position: relative;
  -webkit-transition-duration: 300ms !important;
  -o-transition-duration: 300ms !important;
  transition-duration: 300ms !important;
  &.foto{
    width: 100%;
  }
}
.hcsr-container .slide-effect-2-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1b1b22;
  border-bottom: 100px solid #1b1b22;
  margin-bottom: -100px;
  /* -webkit-transition: -webkit-transform 300ms ease;
   -o-transition: -o-transform 300ms ease;
   transition: -webkit-transform 300ms ease;
   -o-transition: transform 300ms ease;
   transition: transform 300ms ease;
   transition: transform 300ms ease, -webkit-transform 300ms ease;*/
}
.hcsr-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #0b0b14;
  opacity: 0.6;
  -webkit-transition: opacity 300ms ease;
  -o-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}
.obj-fit-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.hcsr-expanded {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -110px;
  text-align: right;
  padding: 11.2% 11.2% 32px;
  -webkit-transition: -webkit-transform 300ms ease;
  -moz-transition: -moz-transform 300ms ease;
  -o-transition: -o-transform 300ms ease;
  transition: transform 300ms ease;
}
@-webkit-keyframes show-img-1 {
  0% {
    visibility: hidden; }
  66.6% {
    visibility: hidden; }
  66.7% {
    visibility: visible; }
  100% {
    visibility: visible; } }

@-moz-keyframes show-img-1 {
  0% {
    visibility: hidden; }
  66.6% {
    visibility: hidden; }
  66.7% {
    visibility: visible; }
  100% {
    visibility: visible; } }

@-ms-keyframes show-img-1 {
  0% {
    visibility: hidden; }
  66.6% {
    visibility: hidden; }
  66.7% {
    visibility: visible; }
  100% {
    visibility: visible; } }

@-o-keyframes show-img-1 {
  0% {
    visibility: hidden; }
  66.6% {
    visibility: hidden; }
  66.7% {
    visibility: visible; }
  100% {
    visibility: visible; } }

@keyframes show-img-1 {
  0% {
    visibility: hidden; }
  66.6% {
    visibility: hidden; }
  66.7% {
    visibility: visible; }
  100% {
    visibility: visible; } }
@-webkit-keyframes slide-bg-2 {
  33.3% {
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(0.05, 1);
    -moz-transform: scale(0.05, 1);
    -ms-transform: scale(0.05, 1);
    -o-transform: scale(0.05, 1);
    transform: scale(0.05, 1); }
  66.6% {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
  66.7% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
  100% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(0, 1);
    -moz-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    -o-transform: scale(0, 1);
    transform: scale(0, 1); } }

@-moz-keyframes slide-bg-2 {
  33.3% {
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(0.05, 1);
    -moz-transform: scale(0.05, 1);
    -ms-transform: scale(0.05, 1);
    -o-transform: scale(0.05, 1);
    transform: scale(0.05, 1); }
  66.6% {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
  66.7% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
  100% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(0, 1);
    -moz-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    -o-transform: scale(0, 1);
    transform: scale(0, 1); } }

@-ms-keyframes slide-bg-2 {
  33.3% {
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(0.05, 1);
    -moz-transform: scale(0.05, 1);
    -ms-transform: scale(0.05, 1);
    -o-transform: scale(0.05, 1);
    transform: scale(0.05, 1); }
  66.6% {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
  66.7% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
  100% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(0, 1);
    -moz-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    -o-transform: scale(0, 1);
    transform: scale(0, 1); } }

@-o-keyframes slide-bg-2 {
  33.3% {
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(0.05, 1);
    -moz-transform: scale(0.05, 1);
    -ms-transform: scale(0.05, 1);
    -o-transform: scale(0.05, 1);
    transform: scale(0.05, 1); }
  66.6% {
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
  66.7% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
  100% {
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    -webkit-transform: scale(0, 1);
    -moz-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    -o-transform: scale(0, 1);
    transform: scale(0, 1); } }

@keyframes slide-bg-2 {
  33.3% {
    /* -webkit-transform-origin: left top;
     -moz-transform-origin: left top;
     -ms-transform-origin: left top;
     -o-transform-origin: left top;*/
    transform-origin: left top;
    -webkit-transform: scale(0.05, 1);
    -moz-transform: scale(0.05, 1);
    -ms-transform: scale(0.05, 1);
    -o-transform: scale(0.05, 1);
    transform: scale(0.05, 1); }
  66.6% {
    /* -webkit-transform-origin: left center;
     -moz-transform-origin: left center;
     -ms-transform-origin: left center;
     -o-transform-origin: left center;*/
    transform-origin: left center;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
  66.7% {
    /* -webkit-transform-origin: right center;
     -moz-transform-origin: right center;
     -ms-transform-origin: right center;
     -o-transform-origin: right center;*/
    transform-origin: right center;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
  100% {
    /*  -webkit-transform-origin: right center;
      -moz-transform-origin: right center;
      -ms-transform-origin: right center;
      -o-transform-origin: right center;*/
    transform-origin: right center;
    -webkit-transform: scale(0, 1);
    -moz-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    -o-transform: scale(0, 1);
    transform: scale(0, 1); } }
[data-aos="slide-effect-2"] .slide-effect-2-inner,
[data-aos="slide-effect-2"] > img {
  visibility: hidden;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  //position: relative;
}

[data-aos="slide-effect-2"]:after {
  z-index: 100;
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(0.05, 0);
  -moz-transform: scale(0.05, 0);
  -ms-transform: scale(0.05, 0);
  -o-transform: scale(0.05, 0);
  transform: scale(0.05, 0);
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -ms-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86); }

[data-aos="slide-effect-2"].se2-secondary:after {
  background-color: #b0b0b6; }

[data-aos="slide-effect-2"].se2-white:after {
  background-color: #ffffff; }
[data-aos="slide-effect-2"].se2-blue:after {
  background-color: $red; }//fafafa   005F96

[data-aos="slide-effect-2"].aos-animate .slide-effect-2-inner,
[data-aos="slide-effect-2"].aos-animate > img {
  -webkit-animation-name: show-img-1;
  -moz-animation-name: show-img-1;
  -ms-animation-name: show-img-1;
  -o-animation-name: show-img-1;
  animation-name: show-img-1; }

[data-aos="slide-effect-2"].aos-animate:after {
  -webkit-animation-name: slide-bg-2;
  -moz-animation-name: slide-bg-2;
  -ms-animation-name: slide-bg-2;
  -o-animation-name: slide-bg-2;
  animation-name: slide-bg-2; }


[data-aos^='slide'][data-aos^='slide'].aos-animate {
  transform: translate(0, 0);
}

[data-aos="slide-effect-btn"].aos-animate a {
  -webkit-animation-name: show-btn-1;
  -moz-animation-name: show-btn-1;
  -ms-animation-name: show-btn-1;
  -o-animation-name: show-btn-1;
   animation-name: show-btn-1;
}
